import CheckboxEntity from '@/lib/formFactory/checkboxEntity';
import ImageInputEntity from '@/lib/formFactory/imageInputEntity';
import InputEntity from '@/lib/formFactory/inputEntity';
import InputSuggestionEntity from '@/lib/formFactory/inputSuggestionsEntity';
import SelectEntity from '@/lib/formFactory/selectEntity';

export default class EntityModel {
    model: any;

    constructor() {
        const titleEntity = new InputEntity('title');
        titleEntity.setTitle('Заголовок');
        titleEntity.setRequired(true);

        const leadEntity = new InputEntity('lead');
        leadEntity.setTitle('Лид');
        leadEntity.setRequired(true);

        const contentEntity = new InputEntity('content');
        contentEntity.setTitle('Контент');
        contentEntity.setRequired(true);

        const showOnMainEntity = new CheckboxEntity('showOnMain');
        showOnMainEntity.setList({
            0: {
                id: 1,
                name: 'Показывать на главной',
                checked: false,
            },
        });

        const sellerTypeEntity = new SelectEntity('sellerType');
        sellerTypeEntity.setTitle('Тип продавца');
        sellerTypeEntity.setList([
            { id: 'auctionHouse', value: 'Аукционный дом' },
            { id: 'gallery', value: 'Галерея' },
            { id: 'painter', value: 'Автор' },
        ]);

        const ownerEntity = new InputSuggestionEntity('seller');
        ownerEntity.setPlaceholder('Художник');

        const photoEntity = new ImageInputEntity('photo');

        this.model = {
            title: titleEntity.entity[titleEntity.entityName],
            lead: leadEntity.entity[leadEntity.entityName],
            content: contentEntity.entity[contentEntity.entityName],
            showOnMain: showOnMainEntity.entity[showOnMainEntity.entityName],
            photo: photoEntity.entity[photoEntity.entityName],
            owner: ownerEntity.entity[ownerEntity.entityName],
            user: null,
            publishedAt: '',
        };
    }
}
