<template>
    <div
        class="mr-fake-checkbox__wrapper"
        :class="{
            'mr-fake-checkbox__disabled': data.disabled === true || disabled === true || disabledAlways === true,
        }"
    >
        <label class="mr-cursor-pointer">
            <input
                :id="`${name}_${data.id}`"
                type="checkbox"
                :value="data.id"
                :checked="checked"
                :name="name"
                :disabled="data.disabled === true || disabled === true || disabledAlways === true"
                @change="updateValue(data, $event)"
            />

            <span class="check-mark"></span>
            <span class="mr-fake-checkbox-default-text">{{ data.name }}</span>
        </label>
    </div>
</template>

<script>
export default {
    props: ['data', 'name', 'handler', 'namespace', 'disabled', 'disabledAlways', 'depend', 'data_value'],
    data() {
        return {
            checked: this.data.checked,
        };
    },
    watch: {
        depend(newVal) {
            this.checked = false;

            setTimeout(() => {
                this.checked = this.data.checked;
            }, 0);
        },
    },
    methods: {
        updateValue(data, $event) {
            const value = {
                name: this.name,
                id: data.id,
                bool: $event.target.checked,
                data_value: this.data_value,
            };
            this.$emit('click', value);

            // TODO:: TS clear
            if (this.handler) {
                this.$store.dispatch(this.handler, { name: this.name, id: data.id, bool: $event.target.checked });
            }
        },
    },
};
</script>
