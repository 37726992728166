
import { Vue, Component } from 'vue-property-decorator';

import NewsEntity from '@/admin/store/content/news/entity';

import TitleReturn from '@/components/TitleReturn.vue';
import InputDefault from '@/components/input/InputMain.vue';
import TextareaBlock from '@/components/form/Textarea.vue';
import CheckboxDefault from '@/components/form/Checkbox.vue';
import ImageInput from '@/components/imageInput/ImageInput.vue';
import BtnSubmit from '@/components/buttons/BtnSubmit/BtnSubmit.vue';
import BtnDefault from '@/components/BtnDefault.vue';
import RemoveModal from '@/components/modules/removeModal.vue';
import Editor from '@/components/modules/Editor.vue';
import InputSuggestions from '@/components/input/InputSuggestions.vue';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';
import { imageToFile } from '@/lib/utils/Utils';

@Component({
    components: {
        TitleReturn,
        InputDefault,
        TextareaBlock,
        CheckboxDefault,
        ImageInput,
        BtnSubmit,
        BtnDefault,
        RemoveModal,
        Editor,
        InputSuggestions,
    },
})
export default class NewsCardComponent extends Vue {
    componentCounter = 0;
    title = this.$route.name === 'content_news_card' ? 'Редактирование новости' : 'Создание новости';
    submitText = this.$route.name === 'content_news_card' ? 'Сохранить' : 'Создать';

    get model() {
        return NewsEntity.model;
    }

    get checkboxItem() {
        return this.model.showOnMain.list[0];
    }

    returnBefore() {
        this.$router.push({ name: 'content_news' });
    }

    updateTitle(title: string) {
        NewsEntity.updateTitle(title);
    }

    updateLead(lead: string) {
        NewsEntity.updateLead(lead);
    }

    updateContent(content: string) {
        NewsEntity.updateContent(content);
    }

    updateShowOnMain(params: { bool: boolean; data_value: unknown; id: number; name: unknown }) {
        NewsEntity.updateShowOnMain(params.bool);
    }

    searchOwner(search: string) {
        NewsEntity.searchOwner(search);
    }

    selectOwner(selected: InputSuggestionItem) {
        NewsEntity.updateSuggestionsSelectedByType({ type: 'owner', selected });
    }

    async handleImage(file: File) {
        const toFile = await imageToFile(file);

        NewsEntity.updatePhotoFile(toFile);
    }

    removePreview() {
        NewsEntity.removeImagePreview();
    }

    send() {
        if (this.$route.name === 'content_news_create') {
            NewsEntity.sendCreate({ name: 'content_news' });

            return;
        }

        NewsEntity.sendEdit();
    }

    cancel() {
        if (this.$route.name === 'content_news_create') {
            this.returnBefore();

            return;
        }

        this.init();
    }

    async init() {
        await NewsEntity.initCardById(+this.$route.params.newsId);
        this.componentCounter++;
    }

    created() {
        if (this.$route.name === 'content_news_create') {
            return;
        }

        this.init();
    }

    beforeDestroy() {
        NewsEntity.reset();
    }
}
