import { Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import EntityBaseModule from '@/admin/store/entity';
import EntityModel from './entityModel';

import store from '@/admin/store';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import RedirectHandlerModule from '@/admin/store/modules/redirectHandler';
import { createNewsCard, editNewsCard, getNewsCard } from '@/admin/api/news';
import { formatCreatedAt, generateSort, getApiErrorMessage, showError } from '@/lib/utils/Utils';
import { processListForSelect } from '@/lib/utils/form';
import { getPainterList } from '@/admin/api/painter';
import SiteModule from '@/admin/store/site';

export const MODULE_NAME = 'NewsEntity';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class NewsEntityModule extends EntityBaseModule {
    cardId: number | null = null;
    model: any;
    requestSort: string;

    constructor(module: NewsEntityModule) {
        super(module);

        const entityModel = new EntityModel();
        this.model = entityModel.model;

        this.requestSort = generateSort({ id: 'name', value: 'ASC' });
    }

    @Mutation
    SET_CARD_ID(id: number) {
        this.cardId = id;
    }

    @Mutation
    SET_TITLE(title: string) {
        this.model.title.value = title;
    }

    @Mutation
    SET_LEAD(lead: string) {
        this.model.lead.value = lead;
    }

    @Mutation
    SET_CONTENT(content: string) {
        this.model.content.value = content;
    }

    @Mutation
    SET_SHOW_ON_MAIN(bool: boolean) {
        this.model.showOnMain.list[0].checked = bool;
    }

    @Mutation
    SET_USER(user: { id: number; fullName: string } | null) {
        this.model.user = user;
    }

    @Mutation
    SET_PUBLISHED_AT(date: string) {
        this.model.publishedAt = date;
    }

    @Action({ rawError: true })
    async initCardById(id: number) {
        this.SET_IS_LOADING(true);

        const result = await getNewsCard(id);
        await this.setInfo(result);

        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    async setInfo(data: any) {
        this.SET_CARD_ID(data.id);

        this.SET_TITLE(data.title ?? '');
        this.SET_LEAD(data.lead ?? '');
        this.SET_CONTENT(data.content ?? '');
        this.SET_SHOW_ON_MAIN(data.showOnMain ?? false);
        this.SET_PHOTO(data.image ?? '');
        this.SET_USER(data.createdByUser ?? null);
        this.SET_PUBLISHED_AT(data.publishDate ? await formatCreatedAt(data.publishDate) : '');
        this.updateSuggestionsSelectedByType(
            data.owner?.id
                ? { type: 'owner', selected: { id: data.owner.id, value: data.owner.name } }
                : { type: 'owner', selected: { id: 0, value: '' } },
        );
    }

    @Action({ rawError: true })
    updateTitle(title: string) {
        this.setValidateState({ index: 'title', field: this.model.title });
        this.SET_TITLE(title);
    }

    @Action({ rawError: true })
    updateLead(lead: string) {
        this.setValidateState({ index: 'lead', field: this.model.lead });
        this.SET_LEAD(lead);
    }

    @Action({ rawError: true })
    updateContent(content: string) {
        this.setValidateState({ index: 'content', field: this.model.content });
        this.SET_CONTENT(content);
    }

    @Action({ rawError: true })
    updateShowOnMain(bool: boolean) {
        this.SET_SHOW_ON_MAIN(bool);
    }

    @Action({ rawError: true })
    async searchOwner(search: string) {
        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ value: search, type: 'owner' });

        const table = await getPainterList(1, await generateSort({ id: 'name', value: 'ASC' }), '', 50);

        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({
            list: await processListForSelect(table.rows),
            type: 'owner',
        });
    }

    @Action({ rawError: true })
    validate() {
        const requiredFields = Object.keys(this.model).filter((key) => this.model[key]?.required === true);

        this.setValidateState({ index: 'title', field: this.model.title });
        this.setValidateState({ index: 'lead', field: this.model.lead });
        this.setValidateState({ index: 'content', field: this.model.content });

        const fieldsWithError = requiredFields.filter((field) => this.model[field]?.error?.class === 'cp-error');

        return fieldsWithError.length > 0 ? true : false;
    }

    @Action({ rawError: true })
    prepareData() {
        const data: {
            title: string;
            lead: string;
            content: string;
            showOnMain: number;
            image?: File;
            owner?: number;
        } = {
            title: this.model.title.value,
            lead: this.model.lead.value,
            content: this.model.content.value,
            showOnMain: this.model.showOnMain.list[0].checked ? 1 : 0,
        };

        if (this.model.photo.file) {
            data['image'] = this.model.photo.file;
        }

        if (this.model.owner.current.id) {
            data['owner'] = this.model.owner.current.id;
        }

        return data;
    }

    @Action({ rawError: true })
    async sendEdit() {
        const isError = await this.validate();

        if (isError) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        if (!this.cardId) {
            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            const data = await this.prepareData();
            const result = await editNewsCard(this.cardId, data);

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
                this.setInfo(result);
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            showError(getApiErrorMessage(error));
        }

        SiteModule.SET_IS_BLOCK(false);
    }

    @Action({ rawError: true })
    async sendCreate(redirect: any = null) {
        const isError = await this.validate();

        if (isError) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            const data = await this.prepareData();
            const result = await createNewsCard(data);

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
                RedirectHandlerModule.changeUrl(redirect);
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            showError(getApiErrorMessage(error));
        }

        SiteModule.SET_IS_BLOCK(false);
    }

    @Action({ rawError: true })
    reset() {
        this.SET_TITLE('');
        this.SET_LEAD('');
        this.SET_CONTENT('');
        this.SET_SHOW_ON_MAIN(false);
        this.SET_PHOTO('');
        this.SET_PHOTO_FILE(null);
        this.SET_PHOTO_PREVIEW('');
        this.SET_USER(null);
        this.SET_PUBLISHED_AT('');
        this.updateSuggestionsSelectedByType({ type: 'owner', selected: { id: 0, value: '' } });

        SiteModule.SET_IS_BLOCK(false);

        this.resetErrorState();
    }

    @Action({ rawError: true })
    resetErrorState() {
        this.RESET_ERROR_BY_FIELD('title');
        this.RESET_ERROR_BY_FIELD('lead');
        this.RESET_ERROR_BY_FIELD('content');
    }
}
export default getModule(NewsEntityModule);
